/* eslint-disable no-magic-numbers */
export class ChannelType {
  static SMS_ID = 1
  static MAILING_ID = 2
  static LANDING_PAGE_ID = 3
  static VOICE_ID = 4
  static PUSH_APP_ID = 5
  static PUSH_WEB_ID = 6
  static VOICE_INTERACTIVE_ID = 7
  static WHATSAPP_ID = 8;

  static AI_TEXT_MAILING_ID = 100
  static AI_TEXT_BODY_WHATSAPP_ID = 101
  static AI_TEXT_HEADER_WHATSAPP_ID = 102
  static AI_TEXT_AUTO_ANSWER_WHATSAPP_ID = 103
  static AI_VOICE_SMS_ID = 104
  static AI_TEXT_INBOX_WHATSAPP_ID = 105

  static SMS = 'sms'
  static MAILING = 'mailing'
  static LANDING_PAGE = 'landing'
  static VOICE_INTERACTIVE = 'voice-interactive'
  static WHATSAPP = 'whatsapp'
}
